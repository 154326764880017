<script>
import PageHeader from "@/components/page-header";
import { ApiClient } from "@/common/http-common"
export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('sidebar.dashboard'),
      items: [
        {
          text: this.$t('sidebar.name')
        },
        {
          text: this.$t('sidebar.dashboard'),
          active: true
        }
      ],
      statisticsCard: []
    };
  },
  methods: {
    getData: function () {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      ApiClient().get("/v1/admin/dashboard/").then(function (response) {
        if (response.data.status == true) {
          if (response.data.item.totalEmployeeEarning != null) {
            self.$set(self.statisticsCard, self.statisticsCard.length, {
              title: self.$t("dashboard.profit.agent"),
              icon: "ri-stack-line",
              value: parseFloat(response.data.item.totalEmployeeEarning).toFixed(2) + " " + "ден.",
            });
          }
        }
        if (response.data.item.totalOwnerEarning != null) {
          self.$set(self.statisticsCard, self.statisticsCard.length, {
            title: self.$t("dashboard.profit.supervisor"),
            icon: "ri-stack-line",
            value: parseFloat(response.data.item.totalOwnerEarning).toFixed(2) + " " + "ден.",
          });
        }
        if (response.data.item.totalLeaderEarning != null) {
          self.$set(self.statisticsCard, self.statisticsCard.length, {
            title: self.$t("dashboard.profit.leader"),
            icon: "ri-stack-line",
            value: parseFloat(response.data.item.totalLeaderEarning).toFixed(2) + " " + "ден.",
          });
        }
        if (response.data.item.totalDevelopersEarning != null) {
          self.$set(self.statisticsCard, self.statisticsCard.length, {
            title: self.$t("dashboard.profit.developers"),
            icon: "ri-stack-line",
            value: parseFloat(response.data.item.totalDevelopersEarning).toFixed(2) + " " + "ден.",
          });
        }
        if (response.data.item.totalCompanyEarning != null) {
          self.$set(self.statisticsCard, self.statisticsCard.length, {
            title: self.$t("dashboard.profit.company"),
            icon: "ri-stack-line",
            value: parseFloat(response.data.item.totalCompanyEarning).toFixed(2) + " " + "ден.",
          });
        }
        if (response.data.item.totalOrderAmount != null) {
          self.$set(self.statisticsCard, self.statisticsCard.length, {
            title: self.$t("dashboard.profit.paid"),
            icon: "ri-stack-line",
            value: parseFloat(response.data.item.totalOrderAmount).toFixed(2) + " " + "ден.",
          });
        }
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }).catch(
        function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }
      )
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    breadCrumbs() {
      if (typeof this.$route.meta.breadCrumb === "function") {
        return this.$route.meta.breadCrumb.call(this, this.$route);
      }
      return false
    },
  }
};
</script>

<template>
    <div class="row">
      
      <div class="col-xl-12">
        <div class="col-xl-12">
      <PageHeader :title="title" :items="items" />
      </div>
        <div class="row">
          <div class="col-md-3" v-for="(data, index) in statisticsCard" :key="index">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
                    <h4 class="mb-0">{{ data.value }}</h4>
                  </div>
                  <div class="text-primary">
                    <i :class="`${data.icon} font-size-24`"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>